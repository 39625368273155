import { get, isArray } from 'lodash';

const axios = require('axios');

// in lovelace
const apiAuth = process.env.GATSBY_UNTS_API_AUTH;
const baseUrl = 'https://untpie.cheekyunts.com/';

const getCounts = (setCounts) => {
  const url = `${baseUrl}CheekyCounts`;
  axios.get(url, {
    method: 'GET',
    headers: {
      Authorization: apiAuth,
    },
  })
    .then((response) => {
      setCounts(response.data);
    })
    .catch((error) => {
      console.log(error);
      setCounts({ errorMessage: 'Error' });
    });
};

const getPurchaseAddressData = (count, setPurchaseAddressData, lovelace) => {
  const url = `${baseUrl}CheekyNFTSale/${count}/${lovelace}`;

  axios.get(url, {
    method: 'GET',
    headers: {
      Authorization: apiAuth,
    },
  })
    .then((results) => {
      console.log(results);
      setPurchaseAddressData(results.data);
    })
    .catch((error) => setPurchaseAddressData({
      error: error.message,
    }));
};

const cancelReservation = (paymentAddress) => {
  if (paymentAddress) {
    const url = `${baseUrl}CancelCheekyAddress/${paymentAddress}`;

    axios.get(url, {
      method: 'GET',
      headers: {
        Authorization: apiAuth,
      },
    })
      .catch((error) => console.log(error));
  }
};

const getMetadata = (onMint, nfts) => {
  if (isArray(nfts)) {
    const requests = nfts.map(({ name }) => axios.get(`${baseUrl}GetNftDetails/${name}`, {
      method: 'GET',
      headers: {
        Authorization: apiAuth,
      },
    }));

    axios.all(requests)
      .then((results) => {
        onMint(results.map((result) => result.data));
      })
      .catch((error) => onMint({
        errorMessage: error.message,
      }));
  }
};

const checkAddress = (
  paymentAddress,
  setPurchaseData,
) => {
  const url = `${baseUrl}CheckCheekyAddress/${paymentAddress}`;

  axios.get(url, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: apiAuth,
    },
  })
    .then((results) => {
      const data = get(results, 'data');
      setPurchaseData(data);
    })
    .catch((error) => setPurchaseData({
      error: error.message,
    }));
};

export {
  getCounts, getPurchaseAddressData, cancelReservation, checkAddress, getMetadata,
};
