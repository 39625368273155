import * as React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';

import './button.scss';

const Button = ({
  isSubmit, label, onButtonClick, secondary, shadow, outline, size,
}) => (
  <button
    onClick={onButtonClick}
    type={isSubmit ? 'submit' : 'button'}
    className={classNames('button', {
      '-secondary': secondary,
      '-shadow': shadow,
      '-outline': outline,
      '-large': size === 'large',
    })}
  >
    {label}
  </button>
);

Button.defaultProps = {
  secondary: false,
  isSubmit: false,
  shadow: false,
  outline: false,
  onButtonClick: null,
  size: null,
};

Button.propTypes = {
  label: PropTypes.node.isRequired,
  onButtonClick: PropTypes.func,
  secondary: PropTypes.bool,
  isSubmit: PropTypes.bool,
  shadow: PropTypes.bool,
  outline: PropTypes.bool,
  size: PropTypes.string,
};

export default Button;
