import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Countdown from 'react-countdown';
import { get } from 'lodash';

import { checkAddress } from '../api/purchaseApi';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Header from '../components/header';
import Button from '../components/button';
import PurhcasedUnts from '../components/purchasedUnts';

import './reciept.scss';

const secondsBetweenChecks = 30;

const getFutureDate = () => {
  const now = new Date().getTime();
  const futureDate = now + (1000 * secondsBetweenChecks);
  return new Date(futureDate);
};

const PageStates = {
  INITIAL: 'INITIAL',
  CHECKING: 'CHECKING',
  LOADING: 'LOADING',
  REFUNDED: 'REFUNDED',
  WAITING: 'WAITING',
  FINISHED: 'FINISHED',
  ADDRESS_MISSING: 'ADDRESS_MISSING',
  ERROR: 'ERROR',
};

const CountdownTimer = ({ checkAgainDate, countdownDone, checkForUnts }) => (checkAgainDate ? (
  <div>
    <h2>You can check again in:</h2>
    <Countdown
      className="reciept__countdown"
      daysInHours
      date={checkAgainDate}
      onComplete={countdownDone}
    />
  </div>
) : (
  <Button label="Check again" onButtonClick={checkForUnts} />
));

CountdownTimer.defaultProps = {
  checkAgainDate: null,
};

CountdownTimer.propTypes = {
  checkAgainDate: PropTypes.instanceOf(Date),
  countdownDone: PropTypes.func.isRequired,
  checkForUnts: PropTypes.func.isRequired,
};

const Reciept = ({ location }) => {
  const [pageState, setPageState] = useState(PageStates.CHECKING);
  const [purchaseData, setPurchaseData] = useState();
  const [checkAgainDate, setCheckAgainDate] = useState();

  console.log(purchaseData);

  const params = new URLSearchParams(location.search);
  const address = params.get('address');

  const setNewFutureTime = (newTime) => {
    if (!newTime) {
      localStorage.removeItem('cheekyWaitTime');
    } else {
      localStorage.setItem('cheekyWaitTime', newTime);
    }

    setCheckAgainDate(newTime);
  };

  const onGetPurchaseData = (newPurchaseData) => {
    const purchaseDataError = get(newPurchaseData, 'error');
    const purchaseDataState = get(newPurchaseData, 'state');

    if (purchaseDataError) {
      setPageState(PageStates.ERROR || purchaseDataState === 'error');
    } else if (purchaseDataState === 'waiting' || purchaseDataState === 'received') {
      setPageState(PageStates.WAITING);
    } else if (purchaseDataState === 'refunded') {
      setNewFutureTime(null);
      setPageState(PageStates.REFUNDED);
    } else if (purchaseDataState === 'finished') {
      setNewFutureTime(null);
      setPurchaseData(newPurchaseData);
      setPageState(PageStates.FINISHED);
    }
  };

  const checkForUnts = () => {
    setPurchaseData(null);
    setPageState(PageStates.LOADING);
    checkAddress(address, onGetPurchaseData);
    setNewFutureTime(getFutureDate());
  };

  useEffect(() => {
    if (address && address !== '') {
      const cheekyWaitTime = localStorage.getItem('cheekyWaitTime') && Date.parse(localStorage.getItem('cheekyWaitTime'));
      const now = new Date();
      console.log(cheekyWaitTime - now);

      if (cheekyWaitTime && cheekyWaitTime - now > 0) {
        setPageState(PageStates.WAITING);
        setCheckAgainDate(new Date(cheekyWaitTime));
      } else {
        checkForUnts();
      }
    } else {
      setPageState(PageStates.ADDRESS_MISSING);
    }
  }, []);

  const countdownDone = () => {
    setNewFutureTime(null);
  };

  return (
    <Layout>
      <Seo title="Cheeky Unts - Connect" />
      <Header />
      <div className="reciept">
        <div className="reciept__mint-again">
          <a className="link" href="/minting" target="_blank">Mint More (in new tab)</a>
        </div>
        <h1>Your New Unts:</h1>
        {(() => {
          switch (pageState) {
            case PageStates.REFUNDED:
              return (
                <>
                  <h2>Refunded</h2>
                  <p>
                    Your order has been refunded.
                  </p>
                </>
              );
            case PageStates.LOADING:
              return <p className="reciept__loading">loading</p>;
            case PageStates.WAITING:
              return (
                <>
                  <p>
                    Nothing yet.
                  </p>
                  <p>
                    Wait patiently like a Good Unt, it can take a few
                    minutes for your purchase to go through.
                  </p>
                  <CountdownTimer
                    checkForUnts={checkForUnts}
                    checkAgainDate={checkAgainDate}
                    countdownDone={countdownDone}
                  />
                </>
              );
            case PageStates.FINISHED:
              return <PurhcasedUnts unts={purchaseData?.nfts} />;
            case PageStates.ADDRESS_MISSING:
              return (
                <>
                  <h2>Hmmm. Something went wrong.</h2>
                  <p>I'm not seeing a purhcase address.</p>
                </>
              );
            case PageStates.ERROR:
              return (
                <>
                  <h2>Hmmm. Something went wrong.</h2>
                  <CountdownTimer
                    checkForUnts={checkForUnts}
                    checkAgainDate={checkAgainDate}
                    countdownDone={countdownDone}
                  />
                </>
              );
            default: return null;
          }
        })()}
      </div>
    </Layout>
  );
};

Reciept.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default Reciept;
