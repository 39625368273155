import React from 'react';
import PropTypes from 'prop-types';

import { get, has } from 'lodash';

import './purchasedUnts.scss';

const PurhcasedUnts = ({ unts }) => (
  <div className="purchased-unts">
    {
      unts.map((unt) => {
        const metadata = get(unt, 'metadata');

        const myRegexp = /ipfs:\/\/(.*)/;

        const imageId = metadata.image.indexOf('ipfs://') === 0 ? myRegexp.exec(metadata.image)[1] : metadata.image;

        return (
          <div className="purchased-unts__unt" key={unt.id}>
            <img alt={unt.name} src={`https://gw2.easy-ipfs.com/ipfs/${imageId}`} />
            <h2>
              {unt.name}
            </h2>
            <ul>
              {
                has(metadata, 'frame') && (
                <li>
                  Frame:
                  {' '}
                  {metadata.frame}
                </li>
                )
              }
              {
                has(metadata, 'unt') && <li>{metadata.unt}</li>
              }
              {
                has(metadata, 'location') && <li>{metadata.location}</li>
              }
              {
                has(metadata, 'special') && <li>{metadata.special}</li>
              }
              {
                has(metadata, 'face') && <li>{metadata.face}</li>
              }
              {
                has(metadata, 'lefty') && <li>{metadata.lefty}</li>
              }
              {
                has(metadata, 'righty') && <li>{metadata.righty}</li>
              }
              {
                has(metadata, 'eyes') && <li>{metadata.eyes}</li>
              }
              {
                has(metadata, 'head') && <li>{metadata.head}</li>
              }
              {
                has(metadata, 'back') && <li>{metadata.back}</li>
              }
              {
                has(metadata, 'accessory') && <li>{metadata.accessory}</li>
              }
              {
                has(metadata, 'mouth') && <li>{metadata.mouth}</li>
              }
            </ul>
          </div>
        );
      })
    }
  </div>
);

PurhcasedUnts.propTypes = {
  unts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      image: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
};

export default PurhcasedUnts;
